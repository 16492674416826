<template>
    <div class="comp">
        <div class="left_tit">
            <div class="gong_name">
                <div class="borderDiv"></div>
                <div class="titDiv">
                    <p class="name_p">产品介绍</p>
                    <p class="eng_p">PRODUCT INTRODUCTION</p>
                </div>
            </div>
            <div class="ints">
                <div class="jieshao">
                    公司核心产品“全一之家”，以“全一之家”小程序为核心载体，微信公众号和官网共同运营管理
                </div>
                <div class="boe_red"></div>
            </div>
            <div class="englis">
                <div class="eng_jie">
                    The company's core product "all in one home", with the "one home" small program as the core carrier,
                    WeChat official account and official website jointly operate and manage.
                </div>
                <div class="cric">
                    <img src="../../assets/imgs/icon.png" alt="">
                </div>
                <div class="ta">全一之家，全心为你</div>
            </div>
        </div>
        <div class="right_img">
            <div class="bacj_div">
                <div class="bac1">
                    <p class="trf">全一之家</p>
                    <p class="ted">为企业&nbsp;&nbsp;提供技术服务</p>
                </div>
                <div class="bac2">
                    <p class="trf">全一之家</p>
                    <p class="ted">为老师&nbsp;&nbsp;提供沟通窗口</p>
                </div>
                <div class="bac3">
                    <p class="trf">全一之家</p>
                    <p class="ted">为家长&nbsp;&nbsp;消除托管焦虑</p>
                </div>
                <div class="bac4">
                    <p class="trf">全一之家</p>
                    <p class="ted">为运营&nbsp;&nbsp;提供高效管理平台</p>
                </div>
                <div class="bac5">
                    <p class="trf">全一之家</p>
                    <p class="ted">为投资人&nbsp;&nbsp;提供新发展机会</p>
                </div>
                <div class="bac6">
                    <p class="trf">全一之家</p>
                    <p class="ted">为全社会&nbsp;&nbsp;培养一流人才</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.comp {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    .left_tit {
        width: 38%;
        height: 100%;
        padding: 0 70px 0 200px;
        // background: red;
        .gong_name {
            position: relative;
            width: 266px;
            height: 108px;
            margin-top: 74px;
            .borderDiv {
                width: 208px;
                height: 108px;
                border-radius: 4px;
                border: 10px solid #f0f0f0;
                position: absolute;
                left: 60px;
                top: -30px;
                z-index: -1;
            }
            .titDiv {
                text-align: left;
                .name_p {
                    color: #2e2133;
                    font-size: 36px;
                    font-weight: 600;
                }
                .eng_p {
                    color: #5c6366;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .ints {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 50px;
            font-size: 16px;
            font-weight: normal;
            color: #454a4d;
            .boe_red {
                width: 100px;
                height: 3px;
                background: #f30b0b;
                margin-top: 15px;
            }
        }
        .englis {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 30px;
            font-size: 16px;
            font-weight: normal;
            color: #8a9499;
            .cric {
                width: 100px;
                height: 20px;
                margin-top: 15px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .ta {
                font-size: 28px;
                font-weight: normal;
                color: #595959;
                margin-top: 20px;
            }
        }
    }
    .right_img {
        width: 26%;
        height: 78%;
        padding: 120px 200px 0 0;
        .bacj_div {
            width: 450px;
            height: 360px;
            display: flex;
            flex-wrap: wrap;
            // background: red;
            .bac1 {
                width: 126px;
                height: 156px;
                background: url(../../assets/imgs/cp1.png) no-repeat center;
                background-size: cover;
                padding-top: 24px;
                padding-left: 24px;
                text-align: left;
                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                }
                .ted {
                    width: 104px;
                    margin-top: 16px;
                    line-height: 26px;
                }
            }
            .bac2 {
                width: 126px;
                height: 156px;
                background: url(../../assets/imgs/cp2.png) no-repeat center;
                background-size: cover;
                padding-top: 24px;
                padding-left: 24px;
                text-align: left;
                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                }
                .ted {
                    width: 104px;
                    margin-top: 16px;
                    line-height: 26px;
                }
            }
            .bac3 {
                width: 126px;
                height: 156px;
                background: url(../../assets/imgs/cp3.png) no-repeat center;
                background-size: cover;
                padding-top: 24px;
                padding-left: 24px;
                text-align: left;
                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                }
                .ted {
                    width: 104px;
                    margin-top: 16px;
                    line-height: 26px;
                }
            }
            .bac4 {
                width: 126px;
                height: 156px;
                background: url(../../assets/imgs/cp4.png) no-repeat center;
                background-size: cover;
                padding-top: 24px;
                padding-left: 24px;
                text-align: left;
                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                }
                .ted {
                    width: 104px;
                    margin-top: 16px;
                    line-height: 26px;
                }
            }
            .bac5 {
                width: 126px;
                height: 156px;
                background: url(../../assets/imgs/cp5.png) no-repeat center;
                background-size: cover;
                padding-top: 24px;
                padding-left: 24px;
                text-align: left;
                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                }
                .ted {
                    width: 104px;
                    margin-top: 16px;
                    line-height: 26px;
                }
            }
            .bac6 {
                width: 126px;
                height: 156px;
                background: url(../../assets/imgs/cp6.png) no-repeat center;
                background-size: cover;
                padding-top: 24px;
                padding-left: 24px;
                text-align: left;
                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                }
                .ted {
                    width: 104px;
                    margin-top: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}
</style>